/** @format */

import { bindable, computedFrom } from 'aurelia-framework';
import { DateTimeUtils as DTU } from '@fonix/web-utils';

const MAX_DAYS = 365;

const DATE_RANGES = {
  Y: 'Y',
  CW: 'CW',
  LW: 'LW',
  CM: 'CM',
  LM: 'LM'
};

const DATERANGES = [
  { name: 'Yesterday', value: DATE_RANGES.Y },
  { name: 'Current Week', value: DATE_RANGES.CW },
  { name: 'Last Week', value: DATE_RANGES.LW },
  { name: 'Current month', value: DATE_RANGES.CM },
  { name: 'Last Month', value: DATE_RANGES.LM }
];

export class DateRangePicker {
  @bindable from;
  @bindable to;
  @bindable filterRanges;
  @bindable pickerOpt;

  @bindable onChanged;
  @bindable onFromChanged;
  @bindable onToChanged;

  constructor() {
    this.pickerOpt = {};

    this.from = DTU.today();
    this.to = DTU.today();
  }

  @computedFrom('filterRanges')
  get dateRanges() {
    let filter = this.filterRanges;
    let _ranges = filter && filter.split ? filter.split(',') : DATERANGES;
    return DATERANGES.filter(r => _ranges.indexOf(r.value) >= 0);
  }

  get maxDays() {
    return (this.pickerOpt && this.pickerOpt.maxDays) || MAX_DAYS;
  }

  handleRangeChanged = ({ value }) => {
    let max = new Date();
    let setDate = ([from, to]) => {
      this.from = from;
      this.to = new Date(Math.min(to, max));

      this.fireChanged(this.from, this.to);
    };

    let startEnd = (unit, prev) => {
      let curr = DTU.startOf(new Date(), unit);

      if (prev) {
        curr = DTU.subtract(curr, 1, unit);
      }

      return [curr, DTU.endOf(curr, unit)];
    };

    switch (DATE_RANGES[value]) {
      case DATE_RANGES.Y:
        {
          let yd = DTU.yesterday();
          setDate([yd, DTU.endOf(yd)]);
        }
        break;
      case DATE_RANGES.CW:
        setDate(startEnd('week'));
        break;
      case DATE_RANGES.LW:
        setDate(startEnd('week', true));
        break;
      case DATE_RANGES.CM:
        setDate(startEnd('month'));
        break;
      case DATE_RANGES.LM:
        setDate(startEnd('month', true));
        break;
    }
  };

  handleToChanged = date => {
    const daysDiff = Math.floor(DTU.diff(this.from, date, 'day'));
    const allowed = Math.abs(daysDiff) <= this.maxDays;
    const _date = allowed ? date : this.from;

    this.to = DTU.endOf(_date);

    //dateTo cant be before dateFrom
    if (DTU.isBefore(this.to, this.from)) {
      this.from = DTU.startOf(this.to);
    }

    if (this.onToChanged) {
      this.onToChanged(this.to);
    }
    this.fireChanged(this.from, this.to);
  };

  handleFromChanged = date => {
    this.from = DTU.startOf(date);

    //Set to same as from when changing from
    this.to = DTU.endOf(this.from);

    if (this.onFromChanged) {
      this.onFromChanged(this.from);
    }
    this.fireChanged(this.from, this.to);
  };

  fireChanged(from, to) {
    if (this.onChanged) {
      this.onChanged({ from, to });
    }
  }
}
