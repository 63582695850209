/** @format */

import { bindable, inject } from 'aurelia-framework';
import flatpickr from 'flatpickr';
import { DateTimeUtils as DTU } from '@fonix/web-utils';

//force first day of week to monday
//pass this into proper i10n file when the time comes.
flatpickr.l10ns.default.firstDayOfWeek = 1;

import './date-picker.scss';
//based on flatpickr.js
export class DatePicker {
  @bindable range;
  @bindable options;

  @bindable disabled;
  @bindable dates;
  @bindable label;
  @bindable default;
  @bindable canClear;
  @bindable onDateChanged;
  @bindable onCreated;
  @bindable cssClass;
  //return date in utc iso format
  @bindable isoFormat;

  @bindable leadIcon;
  @bindable trailIcon;

  constructor() {
    //
    this.options = {};
    this.default = null;
    this.pickerObj = null;
    this.disabled = false;

    this.leadIcon = null;
    this.trailIcon = null;
  }

  attached() {
    this.build();
  }

  detached() {
    this.destroy();
  }

  rangeChanged(value) {
    this.range = value === true || value === 'true';
  }

  datesChanged(dates) {
    if (!this.pickerObj) return;
    if (!dates) {
      //hotfix: for some reason sometimes flatpickr has no .config and throws an exception
      if (this.pickerObj.config) {
        this.pickerObj.clear();
      }
      return;
    }
    this.pickerObj.setDate(dates);
  }

  optionsChanged(newOptions) {
    if (this.pickerObj) {
      //only change/allow maxDate for now
      if (newOptions.maxDate) {
        this.pickerObj.set('maxDate', newOptions.maxDate);
      }
    }
  }

  build() {
    if (!this.refInput) {
      return;
    }

    this.destroy();

    let defaultOptions = {
      enableTime: false,
      mode: this.range ? 'range' : 'single',
      dateFormat: 'j F, Y',
      defaultDate: !this.range ? this.default : null,
      onClose: this.onInputClose.bind(this)
    };
    let options = Object.assign({}, defaultOptions, this.options);
    this.pickerObj = new flatpickr(this.refInput, options);

    if (this.dates) {
      this.pickerObj.setDate(this.dates);
    }

    if (this.onCreated) {
      this.onCreated(this.pickerObj);
    }
  }

  onInputClose(dates) {
    let _dates = dates;

    if (this.isoFormat) {
      _dates = _dates.map(d => DTU.toAPI(DTU.format(d, 'YYYY-MM-DD')));
    }

    this.dates = _dates && _dates.length <= 1 ? _dates[0] : _dates;

    if (this.onDateChanged) {
      this.onDateChanged(this.dates);
    }
  }

  destroy() {
    if (this.pickerObj) {
      this.pickerObj.destroy();
    }
  }
}
