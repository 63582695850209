/** @format */

import { bindable } from 'aurelia-framework';
import './color-picker.scss';

const jscolor = (Modernizr => {
  if (typeof Modernizr !== 'undefined') {
    if (!Modernizr.inputtypes.color) {
      return require('./polyfill/jscolor').default;
    }
  }
})(window.Modernizr);

export class ColorPicker {
  @bindable value;
  @bindable onChange;

  constructor() {}

  attached() {
    if (jscolor) {
      jscolor.init();
    }
  }
  
  handleChange(e) {
    // console.log('color-picker:handleChange', e.target.value);
    if (this.onChange) {
      this.onChange(e.target.value);
    }
  }
}
