/** @format */

/**
 * This component needs some heavy refactoring...
 * Break it into smaller pieced and remove all wierd
 * state mutations crap.
 */

import {
  customElement,
  inject,
  bindable,
  DOM,
  computedFrom
} from 'aurelia-framework';
import { TranslationService } from 'services/translationService';
import { DateTimeUtils } from '@fonix/web-utils';

import './date-filter.scss';

const DATERANGES = {
  T: 'T',
  Y: 'Y',
  L3D: 'L3D',
  L7D: 'L7D',
  L14D: 'L14D',
  L30D: 'L30D',
  C: 'C'
};

@inject(DOM.Element, TranslationService)
@customElement('date-filter')
export class DateFilterControl {
  @bindable value;
  @bindable onDateChanged;
  @bindable allowHours;
  @bindable disabled;
  @bindable filterRanges;
  @bindable label;
  @bindable hideCustomPresets;
  @bindable defaultValue;
  @bindable defaultDate;
  @bindable showDropdown;
  @bindable showDatePicker;
  @bindable hideClock;

  constructor(DOMEl, _TranslationService) {
    this._DOMEl = DOMEl;
    this._ts = _TranslationService;

    this.pickerOptions = {
      static: true,
      maxDate: 'today'
    };

    this.ranges = [];
    this.selectedRange = null;
    this.dtFrom = null;
    this.dtTo = null;

    this.hideClock = false;
    this.allowHours = true;
    //
    this.showHours = false;
    this.timeFrom = '00:00';
    this.timeTo = '23:59';
    this.label = 'date';

    this.showDatePicker = false;
    this.filterRanges = null;

    this.onPickerCreated = this.onPickerCreated.bind(this);
    this.onPickerDateChanged = this.onPickerDateChanged.bind(this);
    this.onTimeClick = this.onTimeClick.bind(this);
    this.onRangeChanged = this.onRangeChanged.bind(this);
    this.onTimeChanged = this.onTimeChanged.bind(this);
  }

  @computedFrom('timeFrom', 'timeTo', 'canShowHours')
  get hasFilterHours() {
    return (
      this.canShowHours &&
      (this.timeFrom !== '00:00' || this.timeTo !== '23:59')
    );
  }

  @computedFrom('selectedRange', 'dtFrom')
  get customSelectedValue() {
    if (this.dtFrom && this.selectedRange && this.selectedRange.value === DATERANGES.C) {
      return DateTimeUtils.format(this.dtFrom, 'DAYMONTH')
    } else if (this.dtFrom && this.selectedRange) {
      return this.selectedRange.name
    }
  }

  @computedFrom('selectedRange')
  get canShowHours() {
    return this.selectedRange && this.selectedRange.canShowHours;
  }

  attached() {
    if (!this.value) {
      this.buildRanges();

      if (this.defaultDate && this.defaultValue == 3) {
        this.updateDate(this.defaultDate.dtfrom);
        this.fireChanged();
      } else {
        this.onRangeChanged(this.ranges[this.defaultValue ? this.defaultValue : 0]);
        if (this.defaultDate) {
          switch (this.defaultValue) {
            case 0:
              this.selectedRange.value = DATERANGES.T;
              break;
            case 1:
              this.selectedRange.value = DATERANGES.Y;
              break;
            default:
              this.selectedRange.value = DATERANGES.C;
          }
          this.onPickerDateChanged(this.defaultDate);
        }
      }
    }
  }

  valueChanged(value) {
    if (!value) return;

    if (!this.ranges || !this.ranges.length) {
      this.buildRanges();
    }

    //supports only date object for now
    const dtFromValue = DateTimeUtils.startOf(value);
    if (isNaN(dtFromValue.getTime())) return;

    //find range that corresponds to date
    const isRangeMatch = r => {
      const { dtFrom } = this.calcRangeValue(r.value);
      return dtFromValue.getTime() === dtFrom.getTime();
    };
    const isRangeCustom = r => r.value === DATERANGES.C;

    let foundRange =
      this.ranges.find(isRangeMatch) || this.ranges.find(isRangeCustom);

    this.selectedRange = foundRange;
    this.updateDate(dtFromValue);
  }

  buildRanges(date) {
    const makeRange = (value, tr, canShowHours, selected) => {
      return { value, name: this._ts.getCap(...tr), canShowHours, selected };
    };

    let ranges = [
      makeRange(DATERANGES.T, ['today'], true),
      makeRange(DATERANGES.Y, ['yesterday'], true),
      makeRange(DATERANGES.L3D, ['last_x_days', { days: 3 }]),
      makeRange(DATERANGES.L7D, ['last_x_days', { days: 7 }], false, this.defaultValue === 3),
      makeRange(DATERANGES.L14D, ['last_x_days', { days: 14 }]),
      makeRange(DATERANGES.L30D, ['last_x_days', { days: 30 }]),
      ... !this.hideCustomPresets ? [makeRange(DATERANGES.C, ['custom'], true, !!date)] : []
    ];

    if (this.filterRanges && this.filterRanges.length) {
      ranges = ranges.filter(r => this.filterRanges.indexOf(r.value) >= 0);
    }

    this.ranges = ranges;

  }

  onPickerCreated(picker) {
    this.customDatePicker = picker;
  }

  onRangeChanged(item) {
    this.selectedRange = item;
    if (item.value === DATERANGES.C) {
      this.openDatePicker();
      return;
    } else {
      let { dtFrom, dtTo } = this.calcRangeValue(item.value);
      this.updateDate(dtFrom, dtTo);
      this.showHours = false;
    }

    this.fireChanged();
  }

  updateDate(dtFrom, dtTo) {
    if (!dtTo) {
      dtFrom = DateTimeUtils.startOf(dtFrom);
      dtTo = DateTimeUtils.endOf(dtFrom);
    }

    this.dtFrom = dtFrom;
    this.dtTo = dtTo;

    this.updateTime();
  }

  onTimeClick() {
    if (this.allowHours && this.canShowHours) this.showHours = !this.showHours;
  }

  calcRangeValue(value) {
    let dtTo = new Date();
    let dtFrom = DateTimeUtils.today();

    if (value === DATERANGES.Y) {
      dtFrom = DateTimeUtils.yesterday();
      dtTo = DateTimeUtils.endOf(dtFrom);
    } else if (value === DATERANGES.L3D) {
      dtFrom = DateTimeUtils.startOf(DateTimeUtils.subtract(dtTo, 2, 'day'));
    } else if (value === DATERANGES.L7D) {
      dtFrom = DateTimeUtils.startOf(DateTimeUtils.subtract(dtTo, 6, 'day'));
    } else if (value === DATERANGES.L14D) {
      dtFrom = DateTimeUtils.startOf(DateTimeUtils.subtract(dtTo, 13, 'day'));
    } else if (value === DATERANGES.L30D) {
      dtFrom = DateTimeUtils.startOf(DateTimeUtils.subtract(dtTo, 29, 'day'));
    }

    return {
      dtTo,
      dtFrom
    };
  }

  openDatePicker() {
    if (this.customDatePicker) {
      this.showDatePicker = true;
      this.customDatePicker.open();
    }
  }

  onPickerDateChanged(dates) {
    let date = dates && dates.length ? dates[0] : dates;
    this.buildRanges(date);
    this.updateDate(date);
    this.fireChanged();
  }

  updateTime() {
    if (this.canShowHours) {
      let _timeFrom = DateTimeUtils.parseTime(this.timeFrom);
      let _timeTo = DateTimeUtils.parseTime(this.timeTo);

      this.dtFrom.setMinutes(_timeFrom.getMinutes());
      this.dtFrom.setHours(_timeFrom.getHours());

      this.dtTo.setMinutes(_timeTo.getMinutes());
      this.dtTo.setHours(_timeTo.getHours());
      this.dtTo.setSeconds(59);
    }
  }

  fireChanged() {
    this.showDatePicker = false;

    if (this.onDateChanged) {
      let index = this.ranges.findIndex(i => i.value === this.selectedRange.value);
      this.onDateChanged(this.dtFrom, this.dtTo, index);
    }
  }

  onTimeChanged(time) {
    this.updateTime();
    this.fireChanged();
  }
}
