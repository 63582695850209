import {
  bindable,
  computedFrom,
  inject
} from 'aurelia-framework';
import {
  TranslationService
} from 'services/translationService';
import './simple-grid.scss';

@inject(TranslationService)
export class SimpleGrid {
  @bindable fields;
  @bindable source;
  @bindable search;
  @bindable searchValue;
  @bindable sortProperties;
  @bindable onItemClick;
  @bindable loading;
  @bindable isScrolling;
  @bindable route;
  @bindable multiSelect;
  @bindable multiSelectActions;
  @bindable advancedActions;

  constructor(_TranslationService) {
    this.ts = _TranslationService;
    this.searchValue = '';
    this.loading = false;
    this.sortProperties = {
      field: '',
      reverse: false
    };
    this.actions = {
      sort: this.sort
    };
    this.isScrolling = false;
    this.scrollPosition = {
      top: 0,
      left: 0
    }
    this.multiSelect = false;
    this.multiSelectAll = false;
    this.multiSelectedItems = [];
    this.multiSelectActions = [
      // {name: 'delete', icon: 'trash', action: this.multiSelectDelete.bind(this)},
    ]
    this.advancedActions = [];

    this.headerClick = this.headerClick.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
  }

  @computedFrom('source')
  get pagedSource() {
    return this.source || [];
  }

  @computedFrom('fields')
  get fieldsArray() {
    if (this.fields instanceof Array) {
      return this.fields;
    } else {
      return (this.fields || '').split(',');
    }
  }

  @computedFrom('search')
  get searchFields() {
    if (this.search instanceof Array) {
      return this.search;
    } else {
      return this.search.split(',');
    }
  }

  @computedFrom('multiSelectedItems')
  get multiSelectLabel() {
    return this.ts.getCap('selected').replace('%d', this.multiSelectedItems.length);
  }

  @computedFrom('multiSelectActions')
  get dropdownMultiSelectActions() {
    if (this.multiSelectActions.find(action => (action.dropdown))) {
      return this.multiSelectActions.filter(action => (action.dropdown))
    } else {
      return null;
    }
  }

  loadingChanged() {
    if (this.route && !this.loading && this.scrollContainerRef) {
      var currentRoute = this.route.navModel.router.routes.find(x => x.name === this.route.name)
      if (currentRoute && (currentRoute.settings.scrollTop || currentRoute.settings.scrollLeft)) {
        setTimeout(() => {
          if (this.scrollContainerRef && this.scrollContainerRef.scrollTo) {
            this.scrollContainerRef.scrollTo(currentRoute.settings.scrollLeft, currentRoute.settings.scrollTop)
          }
          currentRoute.settings.scrollTop = 0;
          currentRoute.settings.scrollLeft = 0;
        });
      } else {
        setTimeout(() => {
          if (this.scrollContainerRef && this.scrollContainerRef.scrollTo) {
            this.scrollContainerRef.scrollTo(this.scrollPosition.left, this.scrollPosition.top)
          }
        });
      }
    }
  }

  onRowClick(e, item, index) {
    if (this.onItemClick) {
      if (this.route) {
        var currentRoute = this.route.navModel.router.routes.find(x => x.name === this.route.name)
        if (currentRoute && (currentRoute.settings)) {
          currentRoute.settings.scrollTop = this.scrollPosition.top;
          currentRoute.settings.scrollLeft = this.scrollPosition.left;
        }
      }
      this.onItemClick(item, index, e);
    }
  }

  headerClick(event, header) {
    let elem = event.target;
    let action = this.actions[elem.dataset.action];
    if (action) {
      action.call(this, elem, header);
    }
  }

  sort(elem, header) {
    let factor = +elem.dataset.sortDir || 1;
    if (this.sortProperties.field != header) {
      this.sortProperties.field = header;
      this.sortProperties.reverse = false;
    } else {
      this.sortProperties.reverse = (factor * -1) == -1 ? true : false;
    }
  }

  onScroll(e) {
    if (e.target.scrollTop && e.target.scrollTop > 0) {
      this.isScrolling = true;
    } else {
      this.isScrolling = false;
    }
    this.scrollPosition = {
      top: e.target.scrollTop,
      left: e.target.scrollLeft
    };
  }

  selectAll() {
    if (this.multiSelectAll || this.multiSelectedItems.length != this.source.length) {
      this.source.forEach(item => {
        item.selected = true;
      });
    } else {
      this.source.forEach(item => {
        item.selected = false;
      });
    }

    this.multiSelectedItems = this.source.filter(item => (item.selected))
  }

  selectItem(item) {
    let selectedItem = this.source.find(i => (i.id === item.id))
    selectedItem.selected = (selectedItem.selected) ? false : true;

    this.multiSelectedItems = this.source.filter(item => (item.selected))

    if (this.multiSelectedItems.length == this.source.length && !this.multiSelectAll) {
      this.multiSelectAll = true;
    } else if (this.multiSelectedItems.length != this.source.length && this.multiSelectAll) {
      this.multiSelectAll = false;
    }
  }

  triggerAction(button) {
    if (button && button.action) {
      button.action(button);
    }
  }
}
